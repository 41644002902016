import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useI18n } from 'services/i18n';
import { Background, Container, Row, Col } from 'styles/grid';
import { BiggerText, SecondaryTitle, Text } from 'components/texts';
import NonStretchedImage from 'components/non-stretched-image';
import media from 'styles/media';
import Links from 'components/links';
import Quote from 'images/icons/quote.svg';
import {
  StyledCol,
  ArticleCol,
  BenefitsContainer,
  BenefitsCol,
  LeftTitle,
  LeftText,
  DescriptionContainer,
  CaseStudyLink,
  DescriptionItem,
} from 'components/case-study';

const QuoteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  justify-content: center;
  svg {
    flex: 0 0 30px;
    height: 30px;
    width: 30px;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0;
  }

  ${media.md`
    flex-wrap: nowrap;
    justify-content: flex-start;

    svg {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  `}
`;

const CaseStudyVolcom = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      intro: file(relativePath: { eq: "case-study-volcom/intro.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      case_study_en: file(relativePath: { eq: "case-study-volcom/en/caseStudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      case_study_fr: file(relativePath: { eq: "case-study-volcom/fr/caseStudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <section>
        <SEO
          title={t('seo.title.case-study-volcom')}
          lang={language}
          description={t('seo.description.case-study-volcom')}
        />
        <Container>
          <Row>
            <Col sm={12} md={8}>
              <BiggerText color="fitle" weight="bold">
                {t('case-study-volcom.introductory-bigger-text')}
              </BiggerText>
              <SecondaryTitle as="h1" desktopAlign="left">
                {t('case-study-volcom.title')}
              </SecondaryTitle>
            </Col>
            <StyledCol sm={12} md={4} align="center">
              <NonStretchedImage
                fluid={data.intro.childImageSharp.fluid}
                alt={t(`seo.alt.caseStudyVolcom.intro`)}
              />
            </StyledCol>
          </Row>
        </Container>
      </section>
      <Background>
        <section>
          <BenefitsContainer>
            <Row justify="space-around" align="stretch">
              <BenefitsCol sm={10} md={3}>
                <Text desktopAlign="center" style={{ marginBottom: 10 }}>
                  {t('case-study-volcom.reco-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-volcom.reco-value')}
                </BiggerText>
              </BenefitsCol>
              <BenefitsCol sm={10} md={3}>
                <Text
                  desktopAlign="center"
                  style={{ margin: '20px auto 10px auto', maxWidth: 200 }}
                >
                  {t('case-study-volcom.usage-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-volcom.usage-value')}
                </BiggerText>
              </BenefitsCol>
              <BenefitsCol sm={10} md={3}>
                <Text
                  desktopAlign="center"
                  style={{ margin: '20px auto 10px auto', maxWidth: 200 }}
                >
                  {t('case-study-volcom.rate-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-volcom.rate-value')}
                </BiggerText>
              </BenefitsCol>
            </Row>
          </BenefitsContainer>
        </section>
      </Background>
      <section>
        <Container>
          <Row>
            <DescriptionContainer>
              <DescriptionItem label={t('case-study-volcom.used-product-key')}>
                <>
                  <CaseStudyLink to="/plugin">
                    {t('case-study-volcom.used-product-plugin')}
                  </CaseStudyLink>
                  <CaseStudyLink to="/dashboard">
                    {t('case-study-volcom.used-product-dashboard')}
                  </CaseStudyLink>
                </>
              </DescriptionItem>
              <DescriptionItem
                label={t('case-study-volcom.headquarters-key')}
                value={t('case-study-volcom.headquarters-value')}
              />
              <DescriptionItem
                label={t('case-study-volcom.business-type-key')}
                value={t('case-study-volcom.business-type-value')}
              />
            </DescriptionContainer>
            <Col md={1}></Col>
            <ArticleCol sm={12} md={8}>
              <SecondaryTitle desktopAlign="left" align="left">
                {t('case-study-volcom.article-title')}
              </SecondaryTitle>
              {/* block one */}
              <LeftText>
                <Text as="span" weight="bold">
                  {t('case-study-volcom.article-block-one.paragraph-one-bold')}
                </Text>
                {t('case-study-volcom.article-block-one.paragraph-one')}
              </LeftText>
              <LeftText>
                {t('case-study-volcom.article-block-one.paragraph-two')}
                <Text as="span" weight="bold">
                  {t('case-study-volcom.article-block-one.paragraph-two-bold')}
                </Text>
                {t('case-study-volcom.article-block-one.paragraph-two-part-two')}
              </LeftText>
              {/* block two */}
              <LeftTitle>{t('case-study-volcom.article-block-two.title')}</LeftTitle>
              <LeftText>
                {t('case-study-volcom.article-block-two.paragraph-one')}
                <Text as="span" weight="bold">
                  {t('case-study-volcom.article-block-two.paragraph-one-bold')}
                </Text>
              </LeftText>
              <LeftText>{t('case-study-volcom.article-block-two.paragraph-two')}</LeftText>
              {/* block three */}
              <LeftTitle>{t('case-study-volcom.article-block-three.title')}</LeftTitle>
              <LeftText>
                {t('case-study-volcom.article-block-three.paragraph-one')}
                <Text as="span" weight="bold">
                  {t('case-study-volcom.article-block-three.paragraph-one-bold')}
                </Text>
                {t('case-study-volcom.article-block-three.paragraph-one-part-two')}
                <Text as="span" weight="bold">
                  {t('case-study-volcom.article-block-three.paragraph-one-part-two-bold')}
                </Text>
              </LeftText>
              <Row style={{ marginTop: 60 }}>
                <Col>
                  <QuoteContainer>
                    <Quote />
                    <BiggerText>
                      {t('case-study-volcom.quote.text')}
                      <BiggerText as="span" weight="bold">
                        {t('case-study-volcom.quote.text-bold')}
                      </BiggerText>
                      {t('case-study-volcom.quote.text-part-two')}
                    </BiggerText>
                  </QuoteContainer>
                  <Text style={{ marginLeft: 45 }} weight="bold">
                    {t('case-study-volcom.quote.name')}
                    <Text as="span" weight="normal">
                      {t('case-study-volcom.quote.position')}
                    </Text>
                  </Text>
                </Col>
              </Row>
              <div style={{ marginTop: 80 }}>
                <NonStretchedImage
                  fluid={data['case_study_' + language].childImageSharp.fluid}
                  alt={t(`seo.alt.caseStudyVolcom.caseStudy`)}
                />
              </div>
            </ArticleCol>
          </Row>
        </Container>
      </section>
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default CaseStudyVolcom;
