import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

import { Text, ParagraphTitle, ParagraphText } from 'components/texts';
import { Container, Col } from 'styles/grid';
import media from 'styles/media';
import colors from 'styles/colors';

export const StyledCol = styled(Col)`
  padding: 0;
  margin-top: 20px;
  ${media.md`
    margin-top: 0;
  `}
`;

export const ArticleCol = styled(Col)`
  margin-top: 20px;
  ${media.md`
    margin-top: 0;
  `}
`;

export const BenefitsContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const BenefitsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0px;
  padding-right: 0px;

  p:first-child {
    flex: 1;
  }
`;

export const LeftTitle = styled(ParagraphTitle)`
  text-align: left;
`;

export const LeftText = styled(ParagraphText)`
  text-align: left;
`;

const Label = styled(Text)`
  text-align: left;
  margin-bottom: 0;
`;

const ValueContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 40px;
  ${media.md`
      margin-bottom: 60px;
  `}
`;

const Value = styled(Label)`
  margin: 0;
`;

export const DescriptionContainer = ({ children }) => (
  <Col sm={12} md={3}>
    {children}
  </Col>
);

export const DescriptionItem = ({ label, value, children }) => (
  <>
    <Label weight="bold">{label}</Label>
    <ValueContainer>{value ? <Value>{value}</Value> : children}</ValueContainer>
  </>
);

export const CaseStudyLink = styled(Link)`
  display: block;
  width: fit-content;
  min-width: 11rem;
  line-height: 1.5rem;
  border-radius: 4px;
  background-color: ${colors.lightBlueGrey};
  border: 1px solid ${colors.lighterGrey};
  color: ${colors.black};
  font-size: 1rem;
  text-decoration: none;
  padding: 0.3rem 0.5rem;
  text-align: center;
  margin-bottom: 0.3rem;
  :hover {
    border-color: ${colors.fitle};
  }
`;
